<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col
        sm="12"
        class="package-details-name"
      >
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('users_details.account_details') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.name') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value"
      >
        <p class="mb-0">
          {{ dataUser.name }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.status') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value d-flex"
      >
        <b-badge
          pill
          :variant="'light-' + statusList[1][dataUser.status]"
          :class="'border-' + statusList[1][dataUser.status]"
          class="text-reg-12 py-0 px-3"
        >{{ $t('common.' + statusList[0][dataUser.status]) }}</b-badge>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.email') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value"
      >
        <p class="mb-0">
          {{ dataUser.email }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.phone') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value"
      >
        <p
          class="mb-0"
          dir="ltr"
        >
          {{ dataUser.phone }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.country') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value"
      >
        <p class="mb-0">
          {{ jsonParseCountry(dataUser.country) }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.city') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value"
      >
        <p class="mb-0">
          {{ jsonParseCountry(dataUser.city) }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_data.roles') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value d-flex"
      >
        <!-- <p class="mb-0"> -->
        <b-badge
          v-for="(item, index) in dataUser.user_role"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-3 rounded-8"
        >
          {{ $t('roles.' + item) }}
        </b-badge>
        <!-- </p> -->
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="3"
        class="package-details-name"
      >
        <p class="mb-0">
          {{ $t('users_details.permissions') }}:
        </p>
      </b-col>
      <b-col
        sm="9"
        class="package-details-value d-flex"
      >
        <!-- <p class="mb-0"> -->
        <b-badge
          v-for="(item, index) in dataUser.permissions"
          :key="index"
          variant="light-icon"
          class="bg-icon text-white text-reg-12 mr-3 rounded-8"
        >
          {{ $t('permissions.' + item) }}
        </b-badge>
        <!-- </p> -->
      </b-col>
    </b-row>
    <hr>
    <b-row class="">
      <b-col
        sm="12"
        class="package-details-name py-5 d-flex p-0-30"
      >
        <wameed-btn
          v-if="dataUser.status == 1"
          classes="text-med-14 text-white rounded-10 mx-md-5 mx-0"
          :title="$t('common.stopping')"
          type="button"
          variant="danger"
          @submitAction="showAdministratorModal = true"
        />
        <wameed-btn
          v-else
          classes="text-med-14 text-white rounded-10 mx-md-5 mx-0"
          :title="$t('common.activation')"
          type="button"
          variant="main"
          @submitAction="showAdministratorModal = true"
        />

        <wameed-btn
          classes="ml-auto mr-md-5 mr-0 text-med-14 text-font-secondary rounded-10 "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          onclick="history.back()"
        />
      </b-col>
    </b-row>
    <warning-modal
      :variant="dataUser.status == 1 ? 'danger' : 'main'"
      :variant2="dataUser.status == 1 ? 'danger' : 'success'"
      :icon-name="dataUser.status == 1 ? 'stopping-icon' : 'confirmation-icon'"
      :visible="showAdministratorModal"
      :title="
        dataUser.status == 1
          ? $t('common.stopping_user_title')
          : $t('common.activation_user_title')
      "
      :sub-title="
        dataUser.status == 1
          ? $t('common.stopping_user_subtitle')
          : $t('common.activation_user_subtitle')
      "
      :btn-title="
        dataUser.status == 1 ? $t('common.stopping') : $t('common.activation')
      "
      @close="showAdministratorModal = false"
      @submitAction="
        dataUser.status == 1
          ? stoppingAdministrator()
          : activationAdministrator()
      "
    />
  </div>
</template>

<script>
import WarningModal from '@/components/WarningModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '../../TextArea.vue';

export default {
  components: {
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  props: {
    dataUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showAdministratorModal: false,
      statusList: [
        {
          0: 'inactive',
          1: 'active',
          2: 'not_enabled',
        },
        {
          0: 'danger',
          1: 'success',
          2: 'icon',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      changeAdministratorsStatus: 'changeAdministratorsStatus',
    }),
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    cancelOrder() {
      this.$router.push({
        name: 'administrators',
        params: { lang: this.$i18n.locale },
      });
    },
    activationAdministrator() {
      this.changeAdministratorsStatus({
        id: this.$router.currentRoute.params.id,
        status: 1,
      }).then(() => {
        this.showAdministratorModal = false;
      });
    },
    stoppingAdministrator() {
      this.changeAdministratorsStatus({
        id: this.$router.currentRoute.params.id,
        status: 0,
      }).then(() => {
        this.showAdministratorModal = false;
      });
    },
    jsonParseCountry(json) {
      return JSON.parse(json).name;
    },
  },
};
</script>
