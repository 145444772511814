<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
        v-if="getAdministratorDetails"
        :title="$t('settings.admin_details_title')"
        :sub-title="$t('settings.admin_details_subTitle')"
      />
    </div>
    <section class="wameed-dashboard-page-content_body">
      <div class="mx-4">
        <b-row class="">
          <b-col md="10" class="my-4">
            <administrator-details-table
              v-if="getAdministratorDetails"
              :data-user="getAdministratorDetails"
            />
          </b-col>
        </b-row>
      </div>
    </section>
    <admin-operation-log/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AdministratorDetailsTable from '@/components/pages/users/AdministratorDetailsTable.vue';
import AdminOperationLog from '@/components/pages/users/AdminOperationLog.vue';
import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from '@/components/WameedBtn.vue';
export default {
  components: {
    AdministratorDetailsTable,
    AdminOperationLog,
    PageHeader,
    WameedBtn,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      getAdministratorDetails: 'getAdministratorDetails',
    }),
  },
  mounted() {
    this.loadAdministratorsDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadAdministratorsDetails: 'loadAdministratorsDetails',
    }),
     formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), "days") > 7
          ? this.moment(date).format("DD/MM/YYYY")
          : this.moment(date).fromNow(true);
      }
        return '------';
    },
  },
};
</script>
